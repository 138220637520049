import React, { Component } from 'react';
import '../App.css';
import contract from '../assets/contract.png';

const so1 = () => {
	window.open("https://rarity.tools/");
}

class comunityTool extends Component {


    render() {
        return (
            <div class="boxWrap3">

            <div data-aos="fade-up" class="cR">
               <div class="utilH"><div class="line3"></div>SMART CONTRACT</div>
                <p class="storyCon3">Contract address : 0x1c4c0C9dbB0E675837F51996D01701aeCB5c5c64</p>
                <p class="storyCon3">NFT Name : NEXTEP</p>
                <p class="storyCon3">Symbol : NXTP</p>
                <p class="storyCon3">Total NFTs : 150</p>
            </div>
            <div data-aos="fade-up" class="conPicDiv">
                <img class="conPic" src={contract} />
            </div>



        </div>

        );

    }
}

export default comunityTool;