import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import storyBG from '../assets/storyBg.jpg';
import storyBGMobile from '../assets/storyBgMobile.jpg';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}


class Story extends Component {




    render() {
        return (

            <div class="boxWrap2">

                    <div class="storyCon"></div>
                <div><img class="storyBannerPicMobile" src={storyBGMobile} /></div>
                <div><img class="storyBannerPic" src={storyBG} /></div>

            </div>

        )
    }
}

export default Story;

