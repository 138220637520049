import React, { Component } from 'react';
import '../App.css';


class ractivations extends Component {


    render() {
        return (
            <div>
                <div class="rH" data-aos="fade-up">ROADMAP ACTIVATIONS</div>

                <div class="com2" data-aos="zoom-in">Members Will Have Security In Their Investments Via Smart Contracts, BCGC Green Goat NFT Receipts As Well As Blockchain And Has Graph Transparent Transaction Tracking.</div>
            
            
            
            </div>

        );

    }
}

export default ractivations;