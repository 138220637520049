import React, { Component } from 'react';
import '../components/styles/carousel.css';
import d1 from '../assets/1m.png';
import d2 from '../assets/2m.png';
import d3 from '../assets/3m.png';
import d4 from '../assets/4m.png';
import d5 from '../assets/5m.png';
import d6 from '../assets/6m.png';
import d7 from '../assets/7m.png';
import d8 from '../assets/8m.png';
import d9 from '../assets/9m.png';


class Carousel extends Component {


    render() {
        return (

            <div>

                <div class="slider">
                    <div class="slide-track">
                        <div class="slide">
                            <img class="carouselIMG" src={d1} alt="d5" />
                        </div>
                     <div class="slide">
                            <img class="carouselIMG" src={d2} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d3} alt="d5" />
                        </div>
                        
                        <div class="slide">
                            <img class="carouselIMG" src={d4} alt="d10" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d5} alt="d11" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d6} alt="d5" />
                        </div>
                     <div class="slide">
                            <img class="carouselIMG" src={d7} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d8} alt="d5" />
                        </div>
                        
                        <div class="slide">
                            <img class="carouselIMG" src={d9} alt="d10" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d1} alt="d5" />
                        </div>
                     <div class="slide">
                            <img class="carouselIMG" src={d2} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d3} alt="d5" />
                        </div>
                        
                        <div class="slide">
                            <img class="carouselIMG" src={d4} alt="d10" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d5} alt="d11" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d6} alt="d5" />
                        </div>
                     <div class="slide">
                            <img class="carouselIMG" src={d7} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d8} alt="d5" />
                        </div>
                        
                        <div class="slide">
                            <img class="carouselIMG" src={d9} alt="d10" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Carousel;        