import './App.css';
//import contract from './NFT';
import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
//import Web3 from 'web3';
import { BrowserRouter, Routes } from 'react-router-dom';
import Countdown from 'react-countdown';
import Footer from "./components/footer";
import Story from "./components/story";
import FAQ from "./components/faq";
import Banner from "./assets/logo.png";
import { SocialIcon } from 'react-social-icons';
import Team from "./components/team";
import Comunity from "./components/comunityTool";
import Utility from "./components/utility";
import RDactivations from "./components/ractivation";
import web3 from "web3";
import detectEthereumProvider from '@metamask/detect-provider';
import Roadmap from './components/roadmap';
import animatePic from './assets/unnamed.png';
import WhitePaper from './components/whitePaper';
import Carousel from './components/carousel';
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import websiteBanner from './assets/mainBanner.jpg';
import websiteBannerMobile from './assets/mainBannerMobile.jpg';
import websiteBannerMobile2 from './assets/storyBgMobile2.jpg';
import opensea from './assets/opensea.png';

var Scroll = require('react-scroll');

var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;
var $ = require("jquery");


const openseaLink = () => { 
	window.open("https://opensea.io/collection/i-am-horuna");
}

$(window).on('load', function () {
	setTimeout(function () { // allowing 3 secs to fade out loader
		$('.page-loader').fadeOut('slow');
	}, 3500);
});

let account;
let mintAmount = 1;
let valueOfNFTs = 0;
let totalSupplyNFT;
let maxMintNFTs;
let onlyLeft;
let owner;
let publicSale;
let loginWindow = 0;
let clickedWalletBtn = 0;
let secondPress = 1;
let cost =''

const ABI = [
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_initBaseURI",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_initNotRevealedUri",
				"type": "string"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [],
		"name": "ApprovalCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalToCurrentOwner",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "ApproveToCaller",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "BalanceQueryForZeroAddress",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "quantity",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "MintToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintZeroQuantity",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "bool",
				"name": "_state",
				"type": "bool"
			}
		],
		"name": "pause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "_data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseURI",
				"type": "string"
			}
		],
		"name": "setBaseURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_cost",
				"type": "uint256"
			}
		],
		"name": "setMintRate",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_notRevealedURI",
				"type": "string"
			}
		],
		"name": "setNotRevealedURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggleReveal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "TokenIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "TransferFromIncorrectOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToNonERC721ReceiverImplementer",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "URIQueryForNonexistentToken",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "cost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "MAX_SUPPLY",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "notRevealedUri",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "paused",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "revealed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenOfOwnerByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

const address = "0x1aCe3A50300c53D00fd14D0cE6eFA60b575b5358";
let contract;

class App extends Component {

	state = {
		walletAddress: "",
		totalSupply: "",
		currentPrice: "",
		nextPrice: "",
		nextSessionAmount: "",
		onlyLeftAmount: "",
		statusError: false,
		statusLoading: false,
		success: false,
		nftMintingAmount: '1',
		totalValue: "",
		presaleValue: "40000000000000000",
		maxmint: '',
		_loginWindow: 0,
		_clickedWalletBtn: 0,
		_secondPress: 1,
		_cost: ''
	}

	onSubmitMinus = async event => {
		event.preventDefault();

		mintAmount = mintAmount - 1;

		if (mintAmount < 1) {
			mintAmount = 1
		}


		if (totalSupplyNFT < 10000) {

			onlyLeft = 10000 - totalSupplyNFT;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}
			valueOfNFTs = mintAmount * /*40000000000000000*/ cost;

			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });

		}





	}

	onSubmitPlus = async event => {
		event.preventDefault();

		//,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
		mintAmount = mintAmount + 1;

		if (totalSupplyNFT < 10000) {

			onlyLeft = 10000 - totalSupplyNFT;
			console.log(onlyLeft);

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}
			valueOfNFTs = mintAmount * /*40000000000000000*/ cost;

			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });

		}

	}

	onSubmit = async event => {
		event.preventDefault();

		const provider = await detectEthereumProvider();

		if (provider) {

			console.log('Ethereum successfully detected!');

			// From now on, this should always be true:
			// provider === window.ethereum

			// Access the decentralized web!

			//.....................................................................//

			(async () => {

				const chainId = 1;

				if (window.ethereum.networkVersion !== chainId) {
					try {
						await window.ethereum.request({
							method: 'wallet_switchEthereumChain',
							params: [{ chainId: web3.utils.toHex(chainId) }],
						});
					} catch (err) {
						// This error code indicates that the chain has not been added to MetaMask.
						if (err.code === 4902) {
							await window.ethereum.request({
								method: 'wallet_addEthereumChain',
								params: [
									{
										chainName: 'Ethereum Mainnet',
										chainId: web3.utils.toHex(chainId),
										nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
										rpcUrls: ['https://etherscan.io'],
									},
								],
							});
						}
					}
				}


				if (window.ethereum) {
					await window.ethereum.send('eth_requestAccounts');
					window.web3 = new web3(window.ethereum);


					contract = new window.web3.eth.Contract(ABI, address);


					let accounts = await window.web3.eth.getAccounts();
					account = accounts[0];
					this.setState({ walletAddress: account });

					console.log("Wallet Address" + this.state.walletAddress);

					try {
						totalSupplyNFT = await contract.methods.totalSupply().call();
						this.setState({ totalSupply: totalSupplyNFT });

						console.log("Total Supply:" + totalSupplyNFT);


						if (totalSupplyNFT < 10000) {
							onlyLeft = 10000 - totalSupplyNFT;

							this.setState({ totalValue: /*40000000000000000*/ cost });



						}

						console.log("Value:" + this.state.totalValue);



					} catch (err) {
						console.log("err: " + err);

					}

				}


			})();

			//.....................................................................//






			// Legacy providers may only have ethereum.sendAsync
			const chainId = await provider.request({
				method: 'eth_chainId'
			})

		} else {

			// if the provider is not detected, detectEthereumProvider resolves to null
			console.error('Please install MetaMask!');
			alert('A valid provider could not be found!');

		}


		/*
		if (window.ethereum) {
			handleEthereum();
		  } else {
			window.addEventListener('ethereum#initialized', handleEthereum, {
			  once: true,
			});
		  
			// If the event is not dispatched by the end of the timeout,
			// the user probably doesn't have MetaMask installed.
			setTimeout(handleEthereum, 3000); // 3 seconds
		  }
		  
		  function handleEthereum() {
			const { ethereum } = window;
			if (ethereum && ethereum.isMetaMask) {
			  console.log('Ethereum successfully detected!');
			  // Access the decentralized web!

			  (async () => {


				if (window.ethereum) {
					await window.ethereum.send('eth_requestAccounts');
					window.web3 = new web3(window.ethereum);
	
	
					contract = new window.web3.eth.Contract(ABI, address);
	
	
					let accounts = await window.web3.eth.getAccounts();
					account = accounts[0];
					this.setState({ walletAddress: account });
	
					console.log("Wallet Address" + this.state.walletAddress);
	
					try {
						totalSupplyNFT = await contract.methods.totalSupply().call();
						this.setState({ totalSupply: totalSupplyNFT });
	
						console.log("Total Supply:" + totalSupplyNFT);
	
	
						if (totalSupplyNFT < 200) {
							onlyLeft = 200 - totalSupplyNFT;
	
							this.setState({ totalValue: 175000000000000000000 });
	
	
	
						} 
	
						console.log("Value:" + this.state.totalValue);
	
	
	
					} catch (err) {
						console.log("err: " + err);
	
					}
	
				}
	
	
			})();


			} else {
			  console.log('Please install MetaMask!');
			}
		  }

*/







	}

	onSubmit2 = async event => {
		event.preventDefault();

		console.log(this.state.walletAddress);

		try {
			let owner = await contract.methods.owner().call();


			if (account != owner) {


				try {


					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);

					//contract = new window.web3.eth.Contract(ABI,'0x802ec13e2e3afe078bc15035f80f0f82bea9bc6c');

					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.mint(mintAmount * 1).send({ gasLimit: 285000, maxPriorityFeePerGas: "196000000000", maxFeePerGas: "202000000000", from: account, value: this.state.totalValue * 1 });
					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);

				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);


				}
			} else {

				try {


					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);

					//contract = new window.web3.eth.Contract(ABI,'0x802ec13e2e3afe078bc15035f80f0f82bea9bc6c');

					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.mint(mintAmount * 1).send({ from: account, value: this.state.totalValue * 1 * 0 });
					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);

				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);


				}






			}
		} catch (err) {

			console.log(err);

		}
	}

	secondPress = async event => {
		event.preventDefault();
		loginWindow = 1;
		clickedWalletBtn = 1;

		this.setState({ _loginWindow: loginWindow });
		this.setState({ _clickedWalletBtn: clickedWalletBtn });
	}

	walletConnect = async event => {
		event.preventDefault();
		loginWindow = 1;
		clickedWalletBtn = 1;

		this.setState({ _loginWindow: loginWindow });
		this.setState({ _clickedWalletBtn: clickedWalletBtn });

		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		const provider = await web3Modal.connect();

		//  Enable session (triggers QR Code modal)
		await provider.enable();

		const web3 = new Web3(provider);
		console.log("provider : " + provider);
		// Subscribe to accounts change
		provider.on("accountsChanged", (accounts) => {
			console.log(accounts);
		});

		// Subscribe to chainId change
		provider.on("chainChanged", (chainId) => {
			console.log(chainId);
		});

		// Subscribe to provider connection
		provider.on("connect", (info) => {
			console.log(info);
		});

		// Subscribe to provider disconnection
		provider.on("disconnect", (error) => {
			console.log(error);
		});


		// test if wallet is connected
		if (web3Modal.cachedProvider) {
			// connected now you can get accounts
			console.log("provider :" + web3Modal.cachedProvider);
			const accounts = await web3.eth.getAccounts();

			account = accounts[0];
			this.setState({ walletAddress: account });

			contract = new web3.eth.Contract(ABI, address);
			console.log("contract :" + contract);

			if (provider) {


				(async () => {


					if (provider != "walletconnect") {



						const chainId = 1;

						if (window.ethereum.networkVersion !== chainId) {
							try {
								await window.ethereum.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://etherscan.io'],
											},
										],
									});
								}
							}
						}





						try {
							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });

							console.log("Total Supply:" + totalSupplyNFT);

							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							cost = await contract.methods.cost().call();
							this.setState({_cost: cost});
							console.log("cost :"+ cost)

							owner = await contract.methods.owner().call();
							console.log("Owner" + owner);

							if (owner == account) {
								console.log("owner : " + owner);

								mintAmount = 1;

								onlyLeft = 10000 - totalSupplyNFT;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}

								valueOfNFTs = mintAmount * 0;

								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							} else {
								mintAmount = 1;

								if (totalSupplyNFT == 10000) {

									onlyLeft = 10000 - totalSupplyNFT;
									mintAmount = onlyLeft;
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount = 1;
									onlyLeft = 10000 - totalSupplyNFT;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}
									//mintAmount = onlyLeft;

									valueOfNFTs = mintAmount * /*40000000000000000*/ cost;

									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}


						} catch (err) {
							console.log("err: " + err);

						}
					} else {



						if (WalletConnectProvider.networkVersion !== chainId) {
							try {
								await WalletConnectProvider.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://etherscan.io'],
											},
										],
									});
								}
							}
						}




						try {
							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });

							console.log("Total Supply:" + totalSupplyNFT);

							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							cost = await contract.methods.cost().call();
							this.setState({_cost: cost});
							console.log("cost :"+ cost)

							owner = await contract.methods.owner().call();
							console.log("Owner" + owner);

							if (owner == account) {
								console.log("owner : " + owner)
								onlyLeft = 10000 - totalSupplyNFT;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}

								valueOfNFTs = mintAmount * 0;

								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							} else {
								mintAmount = 1;

								if (totalSupplyNFT == 10000) {

									onlyLeft = 10000 - totalSupplyNFT;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount = 1;
									onlyLeft = 10000 - totalSupplyNFT;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}

									valueOfNFTs = mintAmount * /*40000000000000000*/ cost;

									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}


						} catch (err) {
							console.log("err: " + err);

						}
					}
				})();

				//.....................................................................//






				// Legacy providers may only have ethereum.sendAsync
				const chainId = await provider.request({
					method: 'eth_chainId'
				})

			} else {

				// if the provider is not detected, detectEthereumProvider resolves to null
				console.error('Please install a Valid Wallet');
				alert('A valid provider could not be found!');

			}






		}

	}

	walletDisconnect = async event => {
		event.preventDefault();


		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		// disconnect wallet
		web3Modal.clearCachedProvider();



	}

	closeWindow = async event => {
		event.preventDefault();

		loginWindow = 0;
		this.setState({ _loginWindow: loginWindow });
		console.log("status" + this.state._loginWindow);

		clickedWalletBtn = 0;
		this.setState({ _clickedWalletBtn: clickedWalletBtn })
		console.log("clickedWalletBtn" + this.state._clickedWalletBtn);

		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		// disconnect wallet
		web3Modal.clearCachedProvider();

	}



	render() {

		return (

			<div class="allWrap">

				<div class="light">
					<div class="page-loader">
						<div class="spinner"></div>
						<div class="txt">I am Horuna</div>
					</div>
					<div class="headers">
						<div class="logoName2">
							<img class="name" src={Banner} alt="logo" />

						</div>
						<div class="connect">
							<div><Link activeClass="connect" to="story" spy={true} smooth={true} duration={550}>Story</Link></div>
							<div><Link activeClass="connect" to="rm" spy={true} smooth={true} duration={550}>Roadmap</Link></div>
							<div><Link activeClass="connect" to="faq" spy={true} smooth={true} duration={550}>Faq</Link></div>
						</div>


						<div class="right">
							<div class="connect2">
								{this.state.walletAddress === '' ?
									(<form onSubmit={this.walletConnect}>
										<button class="wallet2" ><b> Wallet Connect</b></button>
									</form>) : (<form onSubmit={this.secondPress}><button class="wallet2" ><b>
										{this.state.walletAddress.slice(0, 3) + ".." + this.state.walletAddress.slice(39, 42)}</b></button></form>)}
							</div>
							<div class="discord"><img class="os" onClick={openseaLink} src={opensea} /></div>

							<div class="discord">
								<SocialIcon class="twitter" url="https://twitter.com/IamHorunaNFT" target="_blank" network="twitter" bgColor="black" style={{ height: 40, width: 40 }} />
							</div>

							<div class="discord">
								<SocialIcon class="discord" url="https://www.instagram.com/iamhoruna/?hl=en" target="_blank" network="instagram" bgColor="black" style={{ height: 40, width: 40 }} />
							</div>
							<div class="discord3"><img class="os" onClick={openseaLink} src={opensea} /></div>

							<div class="discord3">
								<SocialIcon class="twitter" url="https://twitter.com/IamHorunaNFT" target="_blank" network="twitter" bgColor="black" style={{ height: 50, width: 50 }} />
							</div>

							<div class="discord3">
								<SocialIcon class="discord" url="https://www.instagram.com/iamhoruna/?hl=en" target="_blank" network="instagram" bgColor="black" style={{ height: 50, width: 50 }} />
							</div>

							<div class="discord2"><img class="os"  onClick={openseaLink} src={opensea} /></div>


							<div class="discord2">
								<SocialIcon class="twitter" url="https://twitter.com/IamHorunaNFT" target="_blank" network="twitter" bgColor="black" style={{ height: 35, width: 35 }} />
							</div>

							<div class="discord2">
								<SocialIcon class="discord" url="https://www.instagram.com/iamhoruna/?hl=en" target="_blank" network="instagram" bgColor="black" style={{ height: 35, width: 35 }} />
							</div>

							<div class="discordBig"><img class="os"  onClick={openseaLink} src={opensea} /></div>


							<div class="discordBig">
								<SocialIcon class="twitter" url="https://twitter.com/IamHorunaNFT" target="_blank" network="twitter" bgColor="black" style={{ height: 80, width: 80 }} />
							</div>

							<div class="discordBig">
								<SocialIcon class="discord" url="https://www.instagram.com/iamhoruna/?hl=en" target="_blank" network="instagram" bgColor="black" style={{ height: 80, width: 80 }} />
							</div>
						</div>
					</div>

					<div class="websiteBannerPicDivMobile2"><img class="websiteBannerPicMobile2" src={websiteBannerMobile2} /></div>
					<div class="websiteBannerPicDivMobile"><img class="websiteBannerPicMobile" src={websiteBannerMobile} /></div>

					<div class="websiteBannerPicDiv"><img class="websiteBannerPic" src={websiteBanner} /></div>
					{this.state.walletAddress !== "" && this.state._clickedWalletBtn > 0 ? (
						<div class="formMain">
							<div class="loginFormNote">
								<div class="nftblockWalletConnectedALL">


									<div class="mintDiv">
										<div class="ts">{this.state.totalSupply}/10000</div>

										<div class="minting_count_button">

											<div>
												<form onSubmit={this.onSubmitMinus}>
													<button class="btnfos-0-2" type="submit">-</button>
												</form>
											</div>

											<div>
												<div class="nftminter2"><span class="nftamount">{this.state.nftMintingAmount}</span></div>
											</div>


											<div>
												<form onSubmit={this.onSubmitPlus}>
													<button class="btnfos-0-2" type="submit">+</button>
												</form>
											</div>


										</div>

										<div class="mintbuttondiv">
											<form onSubmit={this.onSubmit2}>

												<button class="btnfos-0-3" type="submit">
													Mint for {(this.state.totalValue / 1000000000000000000)} ETH</button>
											</form>
										</div>
										<div>

											{this.state.statusError ? (
												<div class="errorMessage">
													<div >Sorry, something went wrong please try again later</div>
												</div>)
												: null}

											{this.state.statusLoading ? (
												<div class="loadingContainer">
													<div>
														<div class="loadingText"> Waiting For Confirmation</div>
														<div class="loadTextSub">(this can take up to 30 seconds)</div>
													</div>
												</div>)
												: null}

											{this.state.success ? (

												<div class="successfully">MINTING SUCCESSFUL!</div>)
												: null}

										</div></div>
								</div>

							</div>



							<form onSubmit={this.closeWindow}>
								<button class="closeNote">✖</button>
							</form>
						</div>) : null}

					<Element name="story">
						<Story />
					</Element>

					< Carousel />

					<Element name="rm">
						<Roadmap />
					</Element>

					<Element name="faq">
						<FAQ />
					</Element>

					<Footer />

				</div>
			</div >)
	}
}

export default App;
