import React, { Component } from 'react';
import '../App.css';
import faqName from '../assets/faqName.png'

function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 15;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}

window.addEventListener("scroll", reveal);




class FAQ extends Component {


  render() {
    return (

      <div id="faq" class="faqbg">

        <div class="accordin reveal">

          <div class="faqNamePicDiv"><img class="faqNamePic" src={faqName}/></div>

          <details >
            <summary>What’s an NFT?</summary>
            <div class="faq__content ">
              <p class="fContent">NFT stands for “non-fungible token” — a fancy way of saying it’s a unique, one-of-a-kind digital item that users can buy, own, and trade. Some NFTs’ main functions are to be digital art and to look cool; some offer additional utility, like exclusive access to websites or participation in an event. Think of NFTs as rare pieces of art that can also act as a “member’s card”.</p>
            </div>
          </details >
          <details >
            <summary>What is Ethereum?</summary>
            <div class="faq__content">
              <p class="fContent">Ethereum is the technology that powers the cryptocurrency called Ether (ETH). Learn all the details at their website.</p>

            </div>
          </details>
          <details >
            <summary>What is Metamask or Coinbase Wallet?</summary>
            <div class="faq__content">
              <p class="fContent">These are a crypto wallets that stores your Ethereum. It’s needed to purchase and mint a I am Horuna NFT . Having a wallet gives you an Ethereum address (i.e. 0xABCD….1234), which is also where your NFT is stored. Learn more about Metamask or Coinbase Wallet and how easy they are to use here or here.</p>
            </div>
          </details>

          <details >
            <summary>Where does my NFT go after I purchase I am Horuna ?</summary>
            <div class="faq__content">
              <p class="fContent">Your I am Horuna NFT will appear in whatever address, or connected wallet, you used to purchase I am Horuna . You can view it on OpenSea after you purchase.</p>
              <p class="fContent">This sounds awesome.</p>  
            </div>
          </details>

          <details >
            <summary>How do I get involved?</summary>
            <div class="faq__content">
              <p class="fContent">You join the liberation by minting one I am Horuna. Stan Is Love!</p>

            </div>
          </details>


        </div>
      </div>


    )
  }
}

export default FAQ;

