import React, { Component } from 'react';
import { SocialIcon } from 'react-social-icons';
import logoB from '../assets/Nextep-Crypto-Logo-2.png';




class Footer extends Component {


	render() {

		return (

			<div class="footer">

				<div class="right2">
					<div class="discordFooter">
						<SocialIcon class="twitter" url="https://twitter.com/IamHorunaNFT" target="_blank" network="twitter" bgColor="black" style={{ height: 40, width: 40 }} />
					</div>

					<div class="discordFooter">
						<SocialIcon class="discord" url="https://www.instagram.com/iamhoruna/?hl=en" target="_blank" network="instagram" bgColor="black" style={{ height: 40, width: 40 }} />
					</div>

					<div class="discordFooter2">
						<SocialIcon class="twitter" url="https://twitter.com/IamHorunaNFT" target="_blank" network="twitter" bgColor="black" style={{ height: 30, width: 30 }} />
					</div>

					<div class="discordFooter2">
						<SocialIcon class="discord" url="https://www.instagram.com/iamhoruna/?hl=en" target="_blank" network="instagram" bgColor="black" style={{ height: 30, width: 30 }} />
					</div>

					<div class="discordBig">
								<SocialIcon class="twitter" url="https://twitter.com/IamHorunaNFT" target="_blank" network="twitter" bgColor="black" style={{ height: 80, width: 80 }} />
							</div>

							<div class="discordBig">
								<SocialIcon class="discord" url="https://www.instagram.com/iamhoruna/?hl=en" target="_blank" network="instagram" bgColor="black" style={{ height: 80, width: 80 }} />
							</div>
				</div>
				<div class="copyright">Copyright © 2022, I am Horuna</div>
				<div class="copyright">All trademarks and copyrights belong to their respective owners.</div>

			</div>)
	}
}

export default Footer;