import React, { Component } from 'react';
import '../App.css';
import man1 from '../assets/m1.jpg';
import man2 from '../assets/m2.jpg';
import man3 from '../assets/m3.jpg';

function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 15;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}

window.addEventListener("scroll", reveal);








class Team extends Component {



  async componentDidMount() {


  }

  render() {
    return (
      <div class="boxWrap5">

        <div data-aos="fade-up" class="cR">
          <div class="utilH5"><div class="line4"></div>MEET OUR TEAM</div>
          <div class="storyH">Nextep NFT Team</div>
          <p class="storyCon5">Our team combines passion, expertise, transparency and trust to carry out this revolutionary project.</p>

        </div>

        <div data-aos="fade-up" class="teamMain">

          <div class="team1">
            <div><img class="teamPic" src={man1} /></div>
            <div class="nameT">Alexandre</div>
            <div class="nameT2">Switzerland</div>
            <div class="position">Partnership development & Marketing</div>
         </div>

          <div class="team1">
            <div><img class="teamPic" src={man2} /></div>
            <div class="nameT">Atman</div>
            <div class="nameT2">Belgium</div>
            <div class="position">Network Development & Community</div>
          </div>

          <div class="team1">
            <div><img class="teamPic" src={man3} /></div>
            <div class="nameT">Younes</div>
            <div class="nameT2">France</div>
            <div class="position">Partnership Management & Communication</div>
          </div>

        </div>

      </div>
    )
  }
}

export default Team;