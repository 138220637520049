import React, { Component } from 'react';
import '../App.css';
import wP from '../assets/graph-lungwort-c.png';

class WhitePaper extends Component {


    render() {
        return (
            <div class="boxWrap4">

                <div class="wP">
                <div data-aos="fade-up">
                    <img class="wpPic" src={wP} />
                </div>

                <div data-aos="fade-up" class="storyRight">
                    <div class="utilH"><div class="line2"></div>WHITE PAPER</div>
                    <p class="storyCon2">The WHITE PAPER originally used in politics to define the implementation of strategies, is a collection of factual information about the project.</p>
                    <p class="storyCon2">The WHITE PAPER has become a kind of tradition for every cryptocurrency, and it is usually published before a project is launched.</p>
                    <p class="storyCon2">The BITCOIN for example, which is the first of the cryptocurrencies, was unveiled with it’s WHITE PAPER.</p>
                    <p class="storyCon2">We are honored to present our <a href="https://nextepcrypto.com/wp-content/uploads/2022/03/Nextep-Cryptocurrency-WhitePaper.pdf" target="_blank">WHITE PAPER.</a></p>

                </div>
                </div>

                
                <div class="wPMobile">
               

                <div data-aos="fade-up" class="storyRight">
                    <div class="utilH"><div class="line2"></div>WHITE PAPER</div>
                    <p class="storyCon2">The WHITE PAPER originally used in politics to define the implementation of strategies, is a collection of factual information about the project.</p>
                    <p class="storyCon2">The WHITE PAPER has become a kind of tradition for every cryptocurrency, and it is usually published before a project is launched.</p>
                    <p class="storyCon2">The BITCOIN for example, which is the first of the cryptocurrencies, was unveiled with it’s WHITE PAPER.</p>
                    <p class="storyCon2">We are honored to present our <a href="https://nextepcrypto.com/wp-content/uploads/2022/03/Nextep-Cryptocurrency-WhitePaper.pdf" target="_blank">WHITE PAPER.</a></p>

                </div>

                <div data-aos="fade-up" class="wPpicDiv">
                    <img class="wpPic" src={wP} />
                </div>
                </div>
            </div>

            

        );

    }
}

export default WhitePaper;