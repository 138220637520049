import React, { Component } from 'react';
import '../App.css';
import utility from '../assets/utility.png';

class Utility extends Component {


    render() {
        return (

            <div class="boxWrap3">

                <div data-aos="fade-up" class="uR">
                   <div class="utilH"><div class="line"></div>UTILITY</div>
                   <div class="utilH2">Don’t wait any longer and join us</div>
                    <p class="storyCon">Automatically +10% of Nextep on purchase (not combined with the 1st)</p>
                    <p class="storyCon">No fees when purchasing Nextep</p>
                    <p class="storyCon">Whoever collects 7 NFT's will win...</p>
                    <p class="storyCon">Invitation to all our events with VIP place + Nextep private evenings</p>
                    <p class="storyCon">Idem premium + travel + accommodation + restaurants</p>
                    <p class="storyCon">All function of other NFT's (expect wagon 1)</p>

                </div>
                <div data-aos="fade-up" class="utilPicDiv">
                    <img class="utilPic" src={utility} />
                </div>



            </div>


        );

    }
}

export default Utility;