/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import roadmapBg from '../assets/roadmapBg.jpg';
import rmName from '../assets/rmName.png';

class Roadmap extends Component {


	render() {
		return (
			<div class="roadmapMainOne">
				<div class="roadmapMain">
					<div class="rmNamePicDiv"><img class="rmNamePic" src={rmName} /></div>
					<div>
					<div class="mainRM">
						<div class="roadRm">
							<div class="circle"></div>
							<div class="lineRm"></div>
						</div>
						<div class="mainContentRm">
							<div class="titleRM">VISION MAP</div>
							<div class="contentRM">The I am Horuna Nft Club is a humble lifestyle brand. Our vision is for IAH to become a decentralized lifestyle brand by the community, for the community. Our dev. team members have all dabbled professionally on every item in our future roadmaps. Here is some of our vision of what I am Horuna can deliver:</div>
						</div>
					</div>
					<div class="mainRM">

						<div class="roadRm">
							<div class="circle"></div>
							<div class="lineRm"></div>
						</div>
						<div class="mainContentRm">
							<div class="titleRM">APPAREL</div>
							<div class="contentRM">We love street fashion and culture and we aim to create high quality clothing since day one when we established this brand. The I am Horuna brand will become a wearable piece you can wear both IRL and on the Otherside. T-shirts, hoodies, jackets, masks or maybe even our Horuna Gi that our martial arts NFT holders will enjoy.</div>
						</div>
					</div>
					<div class="mainRM">
						<div class="roadRm">
							<div class="circle"></div>
							<div class="lineRm"></div>
						</div>
						<div class="mainContentRm">
							<div class="titleRM">TOKEN</div>
							<div class="contentRM">For the next phases on our journey we will distribute utility tokens to our NFT holders. This token will have a key role in the future of the I am Horuna NFT Club as many activities will only be accessible by using the token.</div>
						</div>
					</div>
					<div class="mainRM">

						<div class="roadRm">
							<div class="circle"></div>
							<div class="lineRm"></div>
						</div>
						<div class="mainContentRm">
							<div class="titleRM">IRL EVENTS + TOURNAMENTS</div>
							<div class="contentRM">We love street fashion and culture and we aim to create high quality clothing since day one when we established this brand. The I am Horuna brand will become a wearable piece you can wear both IRL and on the Otherside. T-shirts, hoodies, jackets, masks or maybe even our Horuna Gi that our martial arts NFT holders will enjoy.</div>
						</div>
					</div>
					<div class="mainRM">

						<div class="roadRm">
							<div class="circle"></div>
							<div class="lineRm"></div>
						</div>
						<div class="mainContentRm">
							<div class="titleRM">CHARITY FOR UKRAINE</div>
							<div class="contentRM">We will be donating to the Ukrainian front line.</div>
						</div>
					</div>
					</div>
				</div>

				<div class="roadmapPicDiv"><img class="roadmapBg" src={roadmapBg} /></div>
			</div>
		);
	}
}

export default Roadmap;

